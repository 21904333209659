var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a navbar we have the component "),
        _c("code", [_vm._v("vs-navbar")]),
        _vm._v(
          ", there is a series of sub components to determine internal elements "
        ),
        _c("code", [_vm._v("vs-navbar-title")]),
        _vm._v(", "),
        _c("code", [_vm._v("vs-spacer")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-navbar",
            {
              staticClass: "p-2",
              model: {
                value: _vm.activeItem,
                callback: function ($$v) {
                  _vm.activeItem = $$v
                },
                expression: "activeItem",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "title" }, slot: "title" },
                [_c("vs-navbar-title", [_c("span", [_vm._v("Vuexy")])])],
                1
              ),
              _c("vs-navbar-item", { attrs: { index: "0" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Home")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "1" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("News")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "2" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Update")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-navbar v-model="activeItem" class="p-2">\n\n    <div slot="title">\n      <vs-navbar-title>\n        <span>Vuexy</span>\n      </vs-navbar-title>\n    </div>\n\n    <vs-navbar-item index="0">\n      <a href="#">Home</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="1">\n      <a href="#">News</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="2">\n      <a href="#">Update</a>\n    </vs-navbar-item>\n  </vs-navbar>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    activeItem: 0\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }