var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Input", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You may need an entry in the menu to simply use the "),
        _c("code", [_vm._v("vs-input")]),
        _vm._v(" component, for example if you need a search engine"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-navbar",
            {
              staticClass: "p-2",
              model: {
                value: _vm.activeItem,
                callback: function ($$v) {
                  _vm.activeItem = $$v
                },
                expression: "activeItem",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "title" }, slot: "title" },
                [_c("vs-navbar-title", [_c("span", [_vm._v("Vuexy")])])],
                1
              ),
              _c("vs-navbar-item", { attrs: { index: "0" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Home")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "1" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("News")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "2" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Update")]),
              ]),
              _c("vs-input", {
                staticClass: "input-rounded-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-search",
                  placeholder: "Search",
                  "icon-no-border": "",
                  size: "small",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-navbar v-model="activeItem" class="p-2">\n\n    <div slot="title">\n      <vs-navbar-title>\n        <span>Vuexy</span>\n      </vs-navbar-title>\n    </div>\n\n    <vs-navbar-item index="0">\n      <a href="#">Home</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="1">\n      <a href="#">News</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="2">\n      <a href="#">Update</a>\n    </vs-navbar-item>\n\n    <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="search" />\n  </vs-navbar>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    activeItem: 0,\n    search: "",\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }