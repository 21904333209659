var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Type", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "If you want you can change the style of the buttons and the navbar by changing the property "
        ),
        _c("code", [_vm._v("type")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-select",
            {
              staticClass: "selectExample mb-5",
              attrs: { label: "Figuras" },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            _vm._l(_vm.types, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
          _c(
            "vs-navbar",
            {
              staticClass: "p-2",
              attrs: { type: _vm.type },
              model: {
                value: _vm.activeItem,
                callback: function ($$v) {
                  _vm.activeItem = $$v
                },
                expression: "activeItem",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("vs-navbar-title", [
                    _c("span", [_vm._v("Type " + _vm._s(_vm.type))]),
                  ]),
                ],
                1
              ),
              _c("vs-navbar-item", { attrs: { index: "0" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Home")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "1" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("News")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "2" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Update")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <vs-select class="selectExample mb-5" label="Figuras" v-model="type" class="p-2">\n      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in types" />\n    </vs-select>\n\n    <vs-navbar :type="type" v-model="activeItem" class="nabarx">\n\n      <div slot="title">\n        <vs-navbar-title>\n          <span>Type ' +
            _vm._s("{{ type }}") +
            "</span>\n        </vs-navbar-title>\n      </div>\n\n      <vs-navbar-item index=\"0\">\n        <a href=\"#\">Home</a>\n      </vs-navbar-item>\n\n      <vs-navbar-item index=\"1\">\n        <a href=\"#\">News</a>\n      </vs-navbar-item>\n\n      <vs-navbar-item index=\"2\">\n        <a href=\"#\">Update</a>\n      </vs-navbar-item>\n    </vs-navbar>\n  </div>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    activeItem: 0,\n    type: 'flat',\n    types: [{\n        value: null,\n        text: 'Default'\n      },\n      {\n        value: 'flat',\n        text: 'Flat'\n      },\n      {\n        value: 'fund',\n        text: 'Fund'\n      },\n      {\n        value: 'border',\n        text: 'border'\n      },\n      {\n        value: 'gradient',\n        text: 'Gradient'\n      },\n      {\n        value: 'shadow',\n        text: 'Shadow'\n      }\n    ]\n  })\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }