var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the color of the Topbar with the property "),
        _c("code", [_vm._v("color")]),
        _vm._v(". You are able to use the Main Colors or RGB and HEX colors"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "demo-alignment my-5" },
            [
              _c(
                "vs-radio",
                {
                  attrs: { "vs-value": "primary" },
                  model: {
                    value: _vm.colorx,
                    callback: function ($$v) {
                      _vm.colorx = $$v
                    },
                    expression: "colorx",
                  },
                },
                [_vm._v("Primary")]
              ),
              _c(
                "vs-radio",
                {
                  attrs: { color: "danger", "vs-value": "danger" },
                  model: {
                    value: _vm.colorx,
                    callback: function ($$v) {
                      _vm.colorx = $$v
                    },
                    expression: "colorx",
                  },
                },
                [_vm._v("Danger")]
              ),
              _c(
                "vs-radio",
                {
                  attrs: { color: "success", "vs-value": "success" },
                  model: {
                    value: _vm.colorx,
                    callback: function ($$v) {
                      _vm.colorx = $$v
                    },
                    expression: "colorx",
                  },
                },
                [_vm._v("Success")]
              ),
              _c(
                "vs-radio",
                {
                  attrs: { color: "warning", "vs-value": "warning" },
                  model: {
                    value: _vm.colorx,
                    callback: function ($$v) {
                      _vm.colorx = $$v
                    },
                    expression: "colorx",
                  },
                },
                [_vm._v("Warning")]
              ),
              _c(
                "vs-radio",
                {
                  attrs: { color: "dark", "vs-value": "dark" },
                  model: {
                    value: _vm.colorx,
                    callback: function ($$v) {
                      _vm.colorx = $$v
                    },
                    expression: "colorx",
                  },
                },
                [_vm._v("Dark")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.colorx,
                    expression: "colorx",
                  },
                ],
                staticClass: "input-color",
                attrs: { type: "color" },
                domProps: { value: _vm.colorx },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.colorx = $event.target.value
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "vs-navbar",
            {
              staticClass: "myNavbar text-white",
              attrs: {
                color: _vm.colorx,
                "text-color": "rgba(255,255,255,.6)",
                "active-text-color": "rgba(255,255,255,1)",
              },
              model: {
                value: _vm.indexActive,
                callback: function ($$v) {
                  _vm.indexActive = $$v
                },
                expression: "indexActive",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("vs-navbar-title", [
                    _vm._v(
                      "\n                        Navbar Color\n                    "
                    ),
                  ]),
                ],
                1
              ),
              _c("vs-navbar-item", { attrs: { index: "0" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Home")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "1" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("News")]),
              ]),
              _c("vs-navbar-item", { attrs: { index: "2" } }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v("Update")]),
              ]),
              _c("vs-spacer"),
              _c("vs-button", {
                attrs: {
                  "color-text": "rgb(255, 255, 255)",
                  color: "rgba(255, 255, 255, 0.3)",
                  type: "flat",
                  icon: "more_horiz",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <input class="input-color" v-model="colorx" type="color">\n  <vs-navbar v-model="indexActive" :color="colorx" text-color="rgba(255,255,255,.6)" active-text-color="rgba(255,255,255,1)" class="myNavbar text-white">\n    <div slot="title">\n      <vs-navbar-title>\n        Navbar Color\n      </vs-navbar-title>\n    </div>\n\n    <vs-navbar-item index="0">\n      <a href="#">Home</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="1">\n      <a href="#">News</a>\n    </vs-navbar-item>\n\n    <vs-navbar-item index="2">\n      <a href="#">Update</a>\n    </vs-navbar-item>\n\n    <vs-spacer></vs-spacer>\n\n    <vs-button color-text="rgb(255, 255, 255)" color="rgba(255, 255, 255, 0.3)" type="flat" icon="more_horiz"></vs-button>\n  </vs-navbar>\n</template>\n\n<script>\nexport default {\n  data: ()=>({\n    colorx:\'#5A8DEE\',\n    indexActive: 0\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }